import { Button, Loader, MantineProvider, createTheme } from "@mantine/core";
import { Notifications, notifications } from "@mantine/notifications";
import { useAuth } from "react-oidc-context";
import Typewriter from "typewriter-effect";

import "./App.css";

const theme = createTheme({
  /** Put your mantine theme override here */
});

export const App = () => {
  const auth = useAuth();

  const handleSignIn = () => {
    auth.signinRedirect({});
  };

  const handleSignOut = () => {
    auth.signoutPopup();
  };

  const testRequest = async () => {
    const response = await fetch("http://localhost:3801/", {
      headers: {
        Authorization: `Bearer ${auth.user?.access_token}`,
      },
    });
    notifications.show({
      title: "Request",
      message: `Status: ${response.status}\nBody: ${JSON.stringify(
        await response.json(),
        null,
        2,
      )}`,
    });
  };

  const getBody = () => {
    if (auth.isLoading) {
      return <Loader color="white" />;
    }

    if (auth.isAuthenticated) {
      return (
        <div>
          <h1>Welcome, {auth.user?.profile.nickname}!</h1>

          <Button
            variant="default"
            color="white"
            onClick={handleSignOut}
            m={"xl"}
          >
            Sign Out
          </Button>
        </div>
      );
    }

    return (
      <Button variant="default" color="white" onClick={handleSignIn}>
        Sign In
      </Button>
    );
  };

  return (
    <MantineProvider theme={theme}>
      <Notifications />
      <div className="App">
        <header className="App-header">
          <Typewriter
            onInit={(typewriter) => {
              typewriter.typeString("Welcome to my website!").start();
            }}
            options={{
              delay: 80,
              deleteSpeed: 50,
            }}
          />
          {getBody()}
          <Button
            variant="default"
            color="white"
            onClick={testRequest}
            m={"xl"}
          >
            Test Request
          </Button>
        </header>
      </div>
    </MantineProvider>
  );
};
