import "@mantine/core/styles.css";
import "@mantine/notifications/styles.css";
import { WebStorageStateStore } from "oidc-client-ts";
import React from "react";
import ReactDOM from "react-dom/client";
import { AuthProvider, AuthProviderProps } from "react-oidc-context";

import { App } from "./App";
import "./index.css";
import reportWebVitals from "./reportWebVitals";

const oidcConfig: AuthProviderProps = {
  authority:
    process.env.REACT_APP_AUTH_AUTHORITY ??
    "https://sso.loganporter.net/application/o/main/",
  client_id:
    process.env.REACT_APP_AUTH_CLIENT_ID ??
    "3yhPqHDrL3oJ4vjZyIy2tA7rQG3RvNx7Zhjmqk3l",
  redirect_uri:
    process.env.REACT_APP_AUTH_REDIRECT_URI ??
    "https://loganporter.net/auth/callback",
  response_type: process.env.REACT_APP_AUTH_RESPONSE_TYPE ?? "code",
  scope: process.env.REACT_APP_AUTH_SCOPE ?? "openid profile email",
  userStore: new WebStorageStateStore({ store: localStorage }),
  onSigninCallback: () => {
    // remove the path and query string from the URL
    // without this the user will be logged out after a refresh
    window.history.replaceState({}, document.title, "/");
  },
};

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);
root.render(
  <React.StrictMode>
    <AuthProvider {...oidcConfig}>
      <App />
    </AuthProvider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
